import React, { useEffect, useState } from 'react';
import { Stack, Typography, Button, Container, Box, } from '@mui/material';
import axios from 'axios';
import { EditPortfolio, PortFolioTable, SavePortfolio } from '../sections/@dashboard/portfolio';
import Iconify from '../components/Iconify';
import { apiUrls } from '../utils/apiUrls';
import { alertPermission, successToast, errorToast } from '../utils/toastNotification';
import DashModel from '../components/Modal';

function Portfolio() {
  const [allPortfolios, setAllPortfolios] = useState([]);
  const [addNewModel, setAddNewModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [editAbleData, setEditAbleData] = useState({});
  console.log(allPortfolios)
  const fetchPortfolios = async () => {
    try {
      const _fetchPortfolios = await axios.get(apiUrls.getPortfolio);
      setAllPortfolios(_fetchPortfolios.data);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  useEffect(() => {
    fetchPortfolios();
  }, []);

  const deletePortfolio = async (portfolio) => {
    try {
      const result = await alertPermission('Are you sure you want to delete this?');

      if (result) {
        await axios.delete(apiUrls.deletePortfolio(portfolio._id));
        successToast('Successfully deleted');
        fetchPortfolios();
      }
    } catch (error) {
      console.log(error.response);
      errorToast(error?.response?.data.message);
    }
  };

  const editPortfolio = (e) => {
    setEditAbleData(e);
    setEditModel(true);
  };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          User
        </Typography>
        <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => setAddNewModel(true)}>
          New Portfolio
        </Button>
      </Stack>

      <PortFolioTable data={allPortfolios} deleteAction={deletePortfolio} editAction={editPortfolio} />

      <DashModel open={addNewModel} setOpen={setAddNewModel}>
        <Container>
          <Box>
            <Stack direction={'row'} justifyContent={'end'}>
              <Button variant="contained" color="error" onClick={() => setAddNewModel(false)}>
                Close
              </Button>
            </Stack>

            <SavePortfolio fetchAll={fetchPortfolios} modelClose={setAddNewModel} />
          </Box>
        </Container>
      </DashModel>

      <DashModel open={editModel} setOpen={setEditModel}>
        <Container>
          <Box>
            <Stack direction={'row'} justifyContent={'end'}>
              <Button variant="contained" color="error" onClick={() => setEditModel(false)}>
                Close
              </Button>
            </Stack>
            <EditPortfolio fetchAll={fetchPortfolios} modelClose={setEditModel} defaultData={editAbleData} />
          </Box>
        </Container>
      </DashModel>
    </Container>
  );
}

export default Portfolio;
