import { useContext } from 'react';
import { Navigate, useRoutes } from 'react-router-dom';
// layouts
import DashboardLayout from './layouts/dashboard';
import LogoOnlyLayout from './layouts/LogoOnlyLayout';
//
import Blog from './pages/Blog';
import User from './pages/User';
import Login from './pages/Login';
import NotFound from './pages/Page404';
import Products from './pages/Products';
import Contact from './pages/Contact';
import DashboardApp from './pages/DashboardApp';
import Section from './pages/Section';
import Portfolio from './pages/Portfolio';
import Career from './pages/Career';
import { UserContext } from './App';
import BlogView from './pages/BlogView';
import Media from './pages/Media';
import Quote from './pages/Quote';
import Review from './pages/Review';
// ----------------------------------------------------------------------

// const isLoggedIn = true;

export default function Router() {
  const { isLoggedIn } = useContext(UserContext);

  return useRoutes([
    {
      path: '/dashboard',
      element: isLoggedIn ? <DashboardLayout /> : <Navigate to="/login" />,
      children: [
        { path: 'app', element: <DashboardApp /> },
        { path: 'section', element: <Section /> },
        { path: 'portfolio', element: <Portfolio /> },
        { path: 'contact', element: <Contact /> },
        { path: 'quote', element: <Quote /> },
        { path: 'career', element: <Career /> },
        { path: 'user', element: <User /> },
        { path: 'review', element: <Review /> },
        { path: 'media', element: <Media /> },
        { path: 'products', element: <Products /> },
        { path: 'blog', element: <Blog /> },
        { path: 'blog/:id', element: <BlogView /> },
      ],
    },
    {
      path: '/',
      element: !isLoggedIn ? <LogoOnlyLayout /> : <Navigate to="/dashboard/section" />,
      children: [
        { path: '/', element: <Navigate to="/dashboard/section" /> },
        { path: 'login', element: <Login /> },
        { path: '404', element: <NotFound /> },
        { path: '*', element: <Navigate to="/404" /> },
      ],
    },
    { path: '*', element: <Navigate to="/404" replace /> },
  ]);
}
