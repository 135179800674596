import React, { useEffect, useState } from 'react';
import { Stack, Typography, Button, Container, Box } from '@mui/material';
import axios from 'axios';
import Modal from 'react-modal';


import { apiUrls } from '../utils/apiUrls';
import { alertPermission, successToast, errorToast } from '../utils/toastNotification';
import { QuoteTable } from '../sections/@dashboard/quote';


function Quote() {
    const [allQuots, setAllQuots] = useState([]);
    const [addNewModel, setAddNewModel] = useState(false);
    
    const fetchQuots = async () => {
        try {
            const _fetchQuotes = await axios.get(apiUrls.getQuote);
            setAllQuots(_fetchQuotes.data);
            console.log(_fetchQuotes);
            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    };

    useEffect(() => {
        fetchQuots();
    }, []);
    const deleteQuote = async (portfolio) => {
        try {
            const result = await alertPermission('Are you sure you want to delete this?');

            if (result) {
                await axios.delete(apiUrls.deleteQuote(portfolio._id));
                successToast('Successfully deleted');
                fetchQuots();
            }
        } catch (error) {
            console.log(error.response);
            errorToast(error?.response?.data.message);
        }
    };
    return (
        <Container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                    Get Quote Message
                </Typography>

            </Stack>
            <QuoteTable data={allQuots} deleteAction={deleteQuote} />

            <Modal isOpen={addNewModel} className="reactMyModal" overlayClassName="reactModalOverlay">
                <Container>
                    <Box>
                        <Stack direction={'row'} justifyContent={'end'}>
                            <Button variant="contained" color="error" onClick={() => setAddNewModel(false)}>
                                Close
                            </Button>
                        </Stack>

                    </Box>
                </Container>
            </Modal>
        </Container>
    );
};
export default Quote;
