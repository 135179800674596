import { Button, Grid } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { errorToast, successToast } from '../../../utils/toastNotification';
import { apiUrls } from '../../../utils/apiUrls';
// import Section from './SectionEditor';
import Review from './ReviewEditor'
import { uploadImage } from '../../../utils/imageUpload';

EditReview.propTypes = {
  fetchAll: PropTypes.func,
  modelClose: PropTypes.func,
  defaultData: PropTypes.object,
};

function EditReview({ fetchAll, modelClose, defaultData }) {
  const [reviewInfo, setReviewInfo] = useState(defaultData);

  const editReview = async () => {
    try {
      const data = {
        ...reviewInfo,
      };

      if (reviewInfo.image) {
        const _uploadImage = await uploadImage(reviewInfo.image);
        data.file = _uploadImage;
      }

      await axios.patch(apiUrls.updateReview(reviewInfo._id), data);

      successToast('Successfully Update Review');
      setReviewInfo({});
      fetchAll();
      modelClose(false);
    } catch (error) {
      console.log(error);

      errorToast();
    }
  };

  return (
    <Review reviewInfo={reviewInfo} setReviewInfo={setReviewInfo}>
      <Grid item md={4} sm={6} xs={12} sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <Button variant="contained" onClick={editReview}>
          Update Review
        </Button>
      </Grid>
    </Review>
  );
}

export default EditReview;
