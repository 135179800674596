import { Button, Grid } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { errorToast, successToast } from '../../../utils/toastNotification';
import { apiUrls } from '../../../utils/apiUrls';
import Section from './SectionEditor';
import { uploadImage } from '../../../utils/imageUpload';

SaveSection.propTypes = {
  fetchAll: PropTypes.func,
  modelClose: PropTypes.func,
};

function SaveSection({ fetchAll, modelClose }) {
  const [sectionInfo, setSectionInfo] = useState({});

  const saveSection = async () => {
    try {
      // const formData = new FormData();

      // const _uploadImage = await uploadImage(sectionInfo.image);

      // formData.append('file', _uploadImage);
      // Object.entries(sectionInfo).map((item) => item[0] !== 'image' && formData.append(item[0], item[1]));

      const data = {
        ...sectionInfo,
      };

      if (sectionInfo.image) {
        const _uploadImage = await uploadImage(sectionInfo.image);
        data.file = _uploadImage;
      }

      await axios.post(apiUrls.saveSection, data);

      successToast('successfully add section');
      setSectionInfo({});
      fetchAll();
      modelClose(false);
    } catch (error) {
      console.log(error);

      errorToast();
    }
  };

  return (
    <Section sectionInfo={sectionInfo} setSectionInfo={setSectionInfo}>
      <Grid item md={4} sm={6} xs={12} sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <Button variant="contained" onClick={saveSection}>
          Save section
        </Button>
      </Grid>
    </Section>
  );
}

export default SaveSection;
