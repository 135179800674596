import React, { useEffect, useState } from 'react';
import { Stack, Typography, Button, Container, Box } from '@mui/material';
import axios from 'axios';
import Modal from 'react-modal';
import Iconify from '../components/Iconify';
import { apiUrls } from '../utils/apiUrls';
import { alertPermission, successToast, errorToast } from '../utils/toastNotification';
import MediaTable from '../sections/@dashboard/Media/MediaTable';
import SaveMedia from '../sections/@dashboard/Media/SaveMedia';
import EditMedia from '../sections/@dashboard/Media/EditMedia';

function Media() {
  const [allMedia, setAllMedia] = useState([]);
  const [addNewModel, setAddNewModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [editAbleData, setEditAbleData] = useState({});

  const fetchMedia = async () => {
    try {
      const _fetchMedia = await axios.get(apiUrls.getMedia);
      setAllMedia(_fetchMedia.data);

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  useEffect(() => {
    fetchMedia();
  }, []);

  const deleteMedia = async (media) => {
    try {
      const result = await alertPermission('Are you sure you want to delete this?');

      if (result) {
        await axios.delete(apiUrls.deleteMedia(media._id));
        successToast('Successfully deleted');
        fetchMedia();
      }
    } catch (error) {
      console.log(error.response);
      errorToast(error?.response?.data.message);
    }
  };

  const editMedia = (e) => {
    setEditAbleData(e);
    setEditModel(true);
  };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Media
        </Typography>
        <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => setAddNewModel(true)}>
          New Media
        </Button>
      </Stack>

      <MediaTable data={allMedia} deleteAction={deleteMedia} editAction={editMedia} />

      <Modal isOpen={addNewModel} className="reactMyModal" overlayClassName="reactModalOverlay">
        <Container>
          <Box>
            <Stack direction={'row'} justifyContent={'end'}>
              <Button variant="contained" color="error" onClick={() => setAddNewModel(false)}>
                Close
              </Button>
            </Stack>
            <SaveMedia fetchAll={fetchMedia} modelClose={setAddNewModel} />
          </Box>
        </Container>
      </Modal>

      <Modal isOpen={editModel} className="reactMyModal" overlayClassName="reactModalOverlay">
        <Container>
          <Box>
            <Stack direction={'row'} justifyContent={'end'}>
              <Button variant="contained" color="error" onClick={() => setEditModel(false)}>
                Close
              </Button>
            </Stack>
            <EditMedia fetchAll={fetchMedia} modelClose={setEditModel} defaultData={editAbleData} />
          </Box>
        </Container>
      </Modal>
    </Container>
  );
}

export default Media;
