/* eslint-disable no-unused-expressions */
import { Button, Grid } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { errorToast, successToast } from '../../../utils/toastNotification';
import { apiUrls } from '../../../utils/apiUrls';
import BlogEditor from './BlogEditor';
import { uploadImage } from '../../../utils/imageUpload';

EditBlog.propTypes = {
  modelClose: PropTypes.func,
  defaultData: PropTypes.object,
};

function EditBlog({ modelClose, defaultData }) {
  const [blogInfo, setBlogInfo] = useState(defaultData);

  const editBlog = async () => {
    try {
      const data = {
        ...blogInfo,
      };

      if (blogInfo.image) {
        const _uploadImage = await uploadImage(blogInfo.image);
        data.file = _uploadImage;
      }

      await axios.patch(apiUrls.updateBlog(blogInfo._id), data);

      successToast('successfully update blog');
      setBlogInfo({});
      modelClose(false);
      window.location.reload();
    } catch (error) {
      console.log(error);

      errorToast();
    }
  };

  return (
    <BlogEditor blogInfo={blogInfo} setBlogInfo={setBlogInfo} >
      <Grid item md={4} sm={6} xs={12} sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <Button variant="contained" onClick={editBlog}>
          Update blog
        </Button>
      </Grid>
    </BlogEditor>
  );
}

export default EditBlog;
