import { Button, Grid } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { errorToast, successToast } from '../../../utils/toastNotification';
import { apiUrls } from '../../../utils/apiUrls';
import ReviewEditor from './ReviewEditor';
import { uploadImage } from '../../../utils/imageUpload';

SaveReview.propTypes = {
    fetchAll: PropTypes.func,
    modelClose: PropTypes.func,
};

function SaveReview({ fetchAll, modelClose }) {
    const [reviewInfo, setReviewInfo] = useState({});

    const saveReview = async () => {
        try {
            const data = {
                ...reviewInfo,
            };

            if (reviewInfo.image) {
                const _uploadImage = await uploadImage(reviewInfo.image);
                data.file = _uploadImage;
            };

            await axios.post(apiUrls.saveReview, data);

            successToast('Successfully add Review');
            setReviewInfo({});
            fetchAll();
            modelClose(false);
        } catch (error) {
            console.log(error);

            errorToast();
        }
    };

    return (
        <ReviewEditor reviewInfo={reviewInfo} setReviewInfo={setReviewInfo}>
            <Grid item md={2} sm={6} xs={12} sx={{ display: 'flex', alignItems: 'flex-end' }}>
                <Button variant="contained" onClick={saveReview}>
                    Save Review
                </Button>
            </Grid>
        </ReviewEditor>
    );
}

export default SaveReview;
