// component
import Iconify from '../../components/Iconify';

// ----------------------------------------------------------------------

const getIcon = (name) => <Iconify icon={name} width={22} height={22} />;

const navConfig = [
  // {
  //   title: 'dashboard',
  //   path: '/dashboard/app',
  //   icon: getIcon('eva:pie-chart-2-fill'),
  // },
  // {
  //   title: 'user',
  //   path: '/dashboard/user',
  //   icon: getIcon('eva:people-fill'),
  // },
  // {
  //   title: 'product',
  //   path: '/dashboard/products',
  //   icon: getIcon('eva:shopping-bag-fill'),
  // },
  {
    title: 'Portfolio',
    path: '/dashboard/portfolio',
    icon: getIcon('bytesize:portfolio'),
  },
  {
    title: 'Section',
    path: '/dashboard/section',
    icon: getIcon('uim:window-section'),
  },
  {
    title: 'Career',
    path: '/dashboard/career',
    icon: getIcon('ic:baseline-electric-bolt'),
  },

  {
    title: 'Get Quote',
    path: '/dashboard/quote',
    icon: getIcon('eva:pie-chart-2-fill'),
  },
  {
    title: 'blog',
    path: '/dashboard/blog',
    icon: getIcon('eva:file-text-fill'),
  },
  {
    title: 'Contact',
    path: '/dashboard/contact',
    icon: getIcon('eva:file-text-fill'),
  },
  {
    title: 'Review',
    path: '/dashboard/review',
    icon: getIcon('eva:file-text-fill'),
  },
  {
    title: 'Media',
    path: '/dashboard/media',
    icon: getIcon('ic:baseline-perm-media'),
  },
  // {
  //   title: 'register',
  //   path: '/register',
  //   icon: getIcon('eva:person-add-fill'),
  // },
  // {
  //   title: 'Not found',
  //   path: '/404',
  //   icon: getIcon('eva:alert-triangle-fill'),
  // },
];

export default navConfig;
