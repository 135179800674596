import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { Stack, Button } from '@mui/material';
import Iconify from '../../../components/Iconify';


const columns = (deleteAction) => [
    { field: 'name', headerName: 'Name', width: 130 },
    { field: 'email', headerName: 'Email', width: 130 },
    { field: 'phone', headerName: 'Phone', width: 130 },
    { field: 'company', headerName: 'Company', width: 130 },
    { field: 'website', headerName: 'Website', width: 130 },
    { field: 'message', headerName: 'Message', width: 400 },
    {
        field: 'action',
        headerName: 'Action',
        sortable: false,
        width: 200,
        renderCell: ({ row }) => (
            <Stack direction={'row'} spacing={1}>
                <Button
                    onClick={() => deleteAction(row)}
                    variant="contained"
                    color="error"
                    startIcon={<Iconify icon="ant-design:eye-filled" />}
                >
                    Delete
                </Button>
            </Stack>
        ),
    },
];

QuoteTable.propTypes = {
    data: PropTypes.array,
    deleteAction: PropTypes.object
};

function QuoteTable({ data, deleteAction }) {
    return (
        <div style={{ height: 650, width: '100%' }}>
            <DataGrid
                disableColumnMenu
                rows={data}
                columns={columns(deleteAction)}
                pageSize={10}
                rowsPerPageOptions={[10]}
                getRowId={(row) => row._id}
            />
        </div>
    );
}
export default QuoteTable;