import { Button, Grid } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { errorToast, successToast } from '../../../utils/toastNotification';
import { apiUrls } from '../../../utils/apiUrls';
import BlogEditor from './BlogEditor';
import { uploadImage } from '../../../utils/imageUpload';

SaveBlog.propTypes = {
  fetchAll: PropTypes.func,
  modelClose: PropTypes.func,
};

function SaveBlog({ fetchAll, modelClose }) {
  const [blogInfo, setBlogInfo] = useState({});

  const saveCareer = async () => {
    try {
      const data = {
        ...blogInfo,
      };

      if (blogInfo.image) {
        const _uploadImage = await uploadImage(blogInfo.image);
        data.file = _uploadImage;
      }

      await axios.post(apiUrls.saveBlog, data);

      successToast('successfully add blog');
      setBlogInfo({});
      fetchAll();
      modelClose(false);
    } catch (error) {
      console.log(error);

      errorToast();
    }
  };

  return (
    <BlogEditor blogInfo={blogInfo} setBlogInfo={setBlogInfo}>
      <Grid item md={2} sm={6} xs={12} sx={{ display: 'flex', alignItems: 'flex-start' }}>
        <Button variant="contained" onClick={saveCareer}>
          Save blog
        </Button>
      </Grid>
    </BlogEditor>
  );
}

export default SaveBlog;
