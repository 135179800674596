// export const rootUrl = 'https://wetech-backend.herokuapp.com';
// export const rootUrl = 'https://wetech-backend.onrender.com';
export const rootUrl = 'https://api.wetechdigital.com';


export const apiUrls = {
  // section
  saveSection: `${rootUrl}/section/add`,
  getSection: `${rootUrl}/section/list`,
  deleteSection: (id) => `${rootUrl}/section/delete/${id}`,
  updateSection: (id) => `${rootUrl}/section/update/${id}`,

  // portfolio
  savePortfolio: `${rootUrl}/portfolio/add`,
  getPortfolio: `${rootUrl}/portfolio/list`,
  deletePortfolio: (id) => `${rootUrl}/portfolio/delete/${id}`,
  updatePortfolio: (id) => `${rootUrl}/portfolio/update/${id}`,

  // Contact
  getContact: `${rootUrl}/contact/list`,
  deleteContact: (id) => `${rootUrl}/contact/delete/${id}`,

  // get quote
  getQuote: `${rootUrl}/quote/list`,
  deleteQuote: (id) => `${rootUrl}/quote/delete/${id}`,

  // career
  saveCareer: `${rootUrl}/career/add`,
  getCareer: `${rootUrl}/career/list`,
  deleteCareer: (id) => `${rootUrl}/career/delete/${id}`,
  updateCareer: (id) => `${rootUrl}/career/update/${id}`,

  // Blog
  saveBlog: `${rootUrl}/blog/add`,
  getBlog: `${rootUrl}/blog/list`,
  getBlogById: (id) => `${rootUrl}/blog/${id}`,
  deleteBlog: (id) => `${rootUrl}/blog/delete/${id}`,
  updateBlog: (id) => `${rootUrl}/blog/update/${id}`,
  addComment: `${rootUrl}/comment/add`,

  // Review

  saveReview: `${rootUrl}/review/add`,
  getReview: `${rootUrl}/review/list`,
  deleteReview: (id) => `${rootUrl}/review/delete/${id}`,
  updateReview: (id) => `${rootUrl}/review/update/${id}`,


  // Media
  saveMedia: `${rootUrl}/image/add`,
  getMedia: `${rootUrl}/image/list`,
  deleteMedia: (id) => `${rootUrl}/image/delete/${id}`,
  updateMedia: (id) => `${rootUrl}/image/update/${id}`,

  // upload
  imageUpload: `${rootUrl}/file-upload/upload`,

  // auth
  login: `${rootUrl}/user/login`,
  register: `${rootUrl}/user/register`,
};
