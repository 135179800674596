import React, { useEffect, useState } from 'react';
import { Stack, Typography, Button, Container, Box } from '@mui/material';
import axios from 'axios';
import Modal from 'react-modal';
import { CareerTable, EditCareer, SaveCareer } from '../sections/@dashboard/career';
import Iconify from '../components/Iconify';
import { apiUrls } from '../utils/apiUrls';
import { alertPermission, successToast, errorToast } from '../utils/toastNotification';

function Career() {
  const [allCareers, setAllCareers] = useState([]);
  const [addNewModel, setAddNewModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [editAbleData, setEditAbleData] = useState({});

  const fetchCareers = async () => {
    try {
      const _fetchCareers = await axios.get(apiUrls.getCareer);
      setAllCareers(_fetchCareers.data);

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  useEffect(() => {
    fetchCareers();
  }, []);

  const deleteCareer = async (portfolio) => {
    try {
      const result = await alertPermission('Are you sure you want to delete this?');

      if (result) {
        await axios.delete(apiUrls.deleteCareer(portfolio._id));
        successToast('Successfully deleted');
        fetchCareers();
      }
    } catch (error) {
      console.log(error.response);
      errorToast(error?.response?.data.message);
    }
  };

  const editCareer = (e) => {
    setEditAbleData(e);
    setEditModel(true);
  };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          User
        </Typography>
        <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => setAddNewModel(true)}>
          New Career
        </Button>
      </Stack>

      <CareerTable data={allCareers} deleteAction={deleteCareer} editAction={editCareer} />

      <Modal isOpen={addNewModel} className="reactMyModal" overlayClassName="reactModalOverlay">
        <Container>
          <Box>
            <Stack direction={'row'} justifyContent={'end'}>
              <Button variant="contained" color="error" onClick={() => setAddNewModel(false)}>
                Close
              </Button>
            </Stack>
            <SaveCareer fetchAll={fetchCareers} modelClose={setAddNewModel} />
          </Box>
        </Container>
      </Modal>

      <Modal isOpen={editModel} className="reactMyModal" overlayClassName="reactModalOverlay">
        <Container>
          <Box>
            <Stack direction={'row'} justifyContent={'end'}>
              <Button variant="contained" color="error" onClick={() => setEditModel(false)}>
                Close
              </Button>
            </Stack>
            <EditCareer fetchAll={fetchCareers} modelClose={setEditModel} defaultData={editAbleData} />
          </Box>
        </Container>
      </Modal>
    </Container>
  );
}

export default Career;
