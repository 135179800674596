import { Button, Grid } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { errorToast, successToast } from '../../../utils/toastNotification';
import { apiUrls } from '../../../utils/apiUrls';
import CareerEditor from './CareerEditor';
import { uploadImage } from '../../../utils/imageUpload';

SaveCareer.propTypes = {
  fetchAll: PropTypes.func,
  modelClose: PropTypes.func,
};

function SaveCareer({ fetchAll, modelClose }) {
  const [careerInfo, setCareerInfo] = useState({});

  const saveCareer = async () => {
    try {
      const data = {
        ...careerInfo,
      };

      if (careerInfo.image) {
        const _uploadImage = await uploadImage(careerInfo.image);
        data.file = _uploadImage;
      };

      await axios.post(apiUrls.saveCareer, data);

      successToast('Successfully add career');
      setCareerInfo({});
      fetchAll();
      modelClose(false);
    } catch (error) {
      console.log(error);

      errorToast();
    }
  };

  return (
    <CareerEditor careerInfo={careerInfo} setCareerInfo={setCareerInfo}>
      <Grid item md={2} sm={6} xs={12} sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <Button variant="contained" onClick={saveCareer}>
          Save Career
        </Button>
      </Grid>
    </CareerEditor>
  );
}

export default SaveCareer;
