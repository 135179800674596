import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import Iconify from '../../../components/Iconify';


const columns = (deleteAction, editAction) => [
    { field: 'name', headerName: 'Name', width: 130 },
    { field: 'designation', headerName: 'Designation', width: 200 },

    { field: 'message', headerName: 'Message', width: 120 },
    { field: 'videoUrl', headerName: 'Video Url', width: 120 },
  
    {
        field: 'file',
        headerName: 'Image',
        width: 130,
        renderCell: ({ row }) => (
            <Stack direction={'row'} spacing={1}>
                <img src={row.file} alt="img" height={90} width={90} />
            </Stack>
        ),
    },

    {
        field: 'action',
        headerName: 'Action',
        sortable: false,
        width: 200,
        renderCell: ({ row }) => (
            <Stack direction={'row'} spacing={1}>
                <Button
                    onClick={() => editAction(row)}
                    variant="contained"
                    startIcon={<Iconify icon="fa6-solid:pen-to-square" />}
                >
                    Edit
                </Button>
                <Button
                    onClick={() => deleteAction(row)}
                    variant="contained"
                    color="error"
                    startIcon={<Iconify icon="akar-icons:trash-bin" />}
                >
                    Delete
                </Button>
            </Stack>
        ),
    },
];

ReviewTable.propTypes = {
    data: PropTypes.array,
    deleteAction: PropTypes.func,
      editAction: PropTypes.func,
};

function ReviewTable({ data, deleteAction, editAction }) {
    return (
        <div style={{ height: 650, width: '100%' }}>
            <DataGrid
                disableColumnMenu
                rows={data}
                columns={columns(deleteAction, editAction)}
                pageSize={10}
                rowsPerPageOptions={[10]}
                getRowId={(row) => row._id}
            />
        </div>
    );
}

export default ReviewTable;
