import { Stack, Typography, Button, Container, Box, ClickAwayListener } from '@mui/material';
import axios from 'axios';
import React, { useEffect, useState } from 'react';

import { apiUrls } from '../utils/apiUrls';
import Iconify from '../components/Iconify';
import { alertPermission, errorToast, successToast } from '../utils/toastNotification';
import DashModel from '../components/Modal';
import { EditSection, SectionTable, SaveSection } from '../sections/@dashboard/section';

function Section() {
  const [allSections, setAllSections] = useState([]);
  const [addNewModel, setAddNewModel] = useState(false);
  const [editModel, setEditModel] = useState(false);
  const [editAbleData, setEditAbleData] = useState({});
  console.log(allSections)
  const fetchSections = async () => {
    try {
      const _fetchSections = await axios.get(apiUrls.getSection);
      setAllSections(_fetchSections.data);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  useEffect(() => {
    fetchSections();
  }, []);

  const deleteSection = async (section) => {
    try {
      const result = await alertPermission('Are you sure you want to delete this?');

      if (result) {
        await axios.delete(apiUrls.deleteSection(section._id));
        successToast('Successfully deleted');
        fetchSections();
      }
    } catch (error) {
      console.log(error.response);
      errorToast(error?.response?.data.message);
    }
  };

  const editSection = (e) => {
    setEditAbleData(e);
    setEditModel(true);
  };

  return (
    <Container>

      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          User
        </Typography>
        <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => setAddNewModel(true)}>
          New Section
        </Button>
      </Stack>

      <SectionTable data={allSections} deleteAction={deleteSection} editAction={editSection} />

      <DashModel open={addNewModel} setOpen={setAddNewModel}>
        <ClickAwayListener onClickAway={() => setAddNewModel(false)}>
          <Container>
            <Box>
              <Stack direction={'row'} justifyContent={'end'}>
                <Button variant="contained" color="error" onClick={() => setAddNewModel(false)}>
                  Close
                </Button>
              </Stack>
              <SaveSection fetchAll={fetchSections} modelClose={setAddNewModel} />
            </Box>
          </Container>
        </ClickAwayListener>
      </DashModel>

      <DashModel open={editModel} setOpen={setEditModel}>
        <ClickAwayListener onClickAway={() => setEditModel(false)}>
          <Container>
            <Box>
              <Stack direction={'row'} justifyContent={'end'}>
                <Button variant="contained" color="error" onClick={() => setEditModel(false)}>
                  Close
                </Button>
              </Stack>
              <EditSection fetchAll={fetchSections} modelClose={setEditModel} defaultData={editAbleData} />
            </Box>
          </Container>
        </ClickAwayListener>
      </DashModel>

    </Container>
  );
}

export default Section;
