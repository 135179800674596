import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { Button, Stack } from '@mui/material';
import PropTypes from 'prop-types';
import Iconify from '../../../components/Iconify';

const columns = (deleteAction, editAction) => [
  { field: 'imageUrl', headerName: 'image Url', width: 300 },
  { field: 'description', headerName: 'Description', width: 200 },
  {
    field: 'file',
    headerName: 'Image',
    width: 130,
    renderCell: ({ row }) => (
      <Stack direction={'row'} spacing={1}>
        <img src={row.imageUrl} alt="img" height={90} width={90} />
      </Stack>
    ),
  },
  {
    field: 'action',
    headerName: 'Action',
    sortable: false,
    width: 200,
    renderCell: ({ row }) => (
      <Stack direction={'row'} spacing={1}>
        <Button
          onClick={() => editAction(row)}
          variant="contained"
          startIcon={<Iconify icon="fa6-solid:pen-to-square" />}
        >
          Edit
        </Button>
        <Button
          onClick={() => deleteAction(row)}
          variant="contained"
          color="error"
          startIcon={<Iconify icon="akar-icons:trash-bin" />}
        >
          Delete
        </Button>
      </Stack>
    ),
  },
];

MediaTable.propTypes = {
  data: PropTypes.array,
  deleteAction: PropTypes.func,
  editAction: PropTypes.func,
};

function MediaTable({ data, deleteAction, editAction }) {
  return (
    <div style={{ height: 650, width: '100%' }}>
      <DataGrid
        disableColumnMenu
        rows={data}
        columns={columns(deleteAction, editAction)}
        pageSize={10}
        rowsPerPageOptions={[10]}
        getRowId={(row) => row._id}
      />
    </div>
  );
}

export default MediaTable;
