import { Button, Grid } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { errorToast, successToast } from '../../../utils/toastNotification';
import { apiUrls } from '../../../utils/apiUrls';
import MediaEditor from './MediaEditor';
import { uploadImage } from '../../../utils/imageUpload';

SaveMedia.propTypes = {
  fetchAll: PropTypes.func,
  modelClose: PropTypes.func,
};

function SaveMedia({ fetchAll, modelClose }) {
  const [mediaInfo, setMediaInfo] = useState({});

  const saveCareer = async () => {
    try {
      const data = {
        ...mediaInfo,
      };

      if (mediaInfo.image) {
        const _uploadImage = await uploadImage(mediaInfo.image);
        data.imageUrl = _uploadImage;
      };

      await axios.post(apiUrls.saveMedia, data);

      successToast('Successfully add media');
      setMediaInfo({});
      fetchAll();
      modelClose(false);
    } catch (error) {
      console.log(error);
      errorToast();
    }
  };

  return (
    <MediaEditor mediaInfo={mediaInfo} setMediaInfo={setMediaInfo}>
      <Grid item md={2} sm={6} xs={12} sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <Button variant="contained" onClick={saveCareer}>
          Save Media
        </Button>
      </Grid>
    </MediaEditor>
  );
}

export default SaveMedia;
