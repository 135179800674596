import React, { useEffect, useState } from 'react';
import { Stack, Typography, Button, Container, Box } from '@mui/material';
import axios from 'axios';
import Modal from 'react-modal';
// import { ReviewTable, SaveCareer } from '../sections/@dashboard/review';
import { EditReview, ReviewTable, SaveReview } from "../sections/@dashboard/Review"
import Iconify from '../components/Iconify';
import { apiUrls } from '../utils/apiUrls';
import { alertPermission, successToast, errorToast } from '../utils/toastNotification';

function Review() {
    const [allReviews, setAllReviews] = useState([]);
    const [addNewModel, setAddNewModel] = useState(false);
    const [editModel, setEditModel] = useState(false);
    const [editAbleData, setEditAbleData] = useState({});

    const fetchReviews = async () => {
        try {
            const _fetchReviews = await axios.get(apiUrls.getReview);
            setAllReviews(_fetchReviews.data);

            return true;
        } catch (error) {
            console.log(error);
            return false;
        }
    };

    useEffect(() => {
        fetchReviews();
    }, []);

    const deleteReview = async (portfolio) => {
        try {
            const result = await alertPermission('Are you sure you want to delete this?');

            if (result) {
                await axios.delete(apiUrls.deleteReview(portfolio._id));
                successToast('Successfully deleted');
                fetchReviews();
            }
        } catch (error) {
            console.log(error.response);
            errorToast(error?.response?.data.message);
        }
    };

    const editReview = (e) => {
        setEditAbleData(e);
        setEditModel(true);
    };

    return (
        <Container>
            <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
                <Typography variant="h4" gutterBottom>
                    Review
                </Typography>
                <Button variant="contained" startIcon={<Iconify icon="eva:plus-fill" />} onClick={() => setAddNewModel(true)}>
                    New Review
                </Button>
            </Stack>

            <ReviewTable data={allReviews} deleteAction={deleteReview} editAction={editReview} />

            <Modal isOpen={addNewModel} className="reactMyModal" overlayClassName="reactModalOverlay">
                <Container>
                    <Box>
                        <Stack direction={'row'} justifyContent={'end'}>
                            <Button variant="contained" color="error" onClick={() => setAddNewModel(false)}>
                                Close
                            </Button>
                        </Stack>
                        <SaveReview fetchAll={fetchReviews} modelClose={setAddNewModel} />
                    </Box>
                </Container>
            </Modal>

            <Modal isOpen={editModel} className="reactMyModal" overlayClassName="reactModalOverlay">
                <Container>
                    <Box>
                        <Stack direction={'row'} justifyContent={'end'}>
                            <Button variant="contained" color="error" onClick={() => setEditModel(false)}>
                                Close
                            </Button>
                        </Stack>
                        <EditReview fetchAll={fetchReviews} modelClose={setEditModel} defaultData={editAbleData} />
                    </Box>
                </Container>
            </Modal>
        </Container>
    );
}

export default Review;
