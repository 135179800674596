/* eslint-disable react-hooks/exhaustive-deps */
import axios from 'axios';
import React, { useEffect, useState } from 'react';
import { useParams, Link as RouterLink, useNavigate } from 'react-router-dom';
import parse from 'html-react-parser';
import { Box, Button, Card, CardContent, Container, Stack, TextareaAutosize } from '@mui/material';
import Modal from 'react-modal';
import { alertPermission, successToast } from '../utils/toastNotification';
import { apiUrls } from '../utils/apiUrls';
import Page from '../components/Page';
import Iconify from '../components/Iconify';
// import DashModel from '../components/Modal';
import { EditBlog } from '../sections/@dashboard/blog';

function BlogView() {
  const { id } = useParams();
  const navigate = useNavigate();

  const [blog, setBlog] = useState({});
  const [editModel, setEditModel] = useState(false);
  const [comment, setComment] = useState('');

  const getBlog = async () => {
    try {
      const _fetchBlogs = await axios.get(apiUrls.getBlogById(id));
      setBlog(_fetchBlogs.data);

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  useEffect(() => {
    getBlog();
  }, [id]);

  const deleteBlog = async () => {
    try {
      const result = await alertPermission('Are you sure you want to delete this?');
      if (result) {
        await axios.delete(apiUrls.deleteBlog(id));
        navigate('/dashboard/blog');
        successToast('Successfully deleted');
      }
    } catch (error) {
      console.log(error);
    }
  };

  const addComment = async () => {
    try {
      await axios.post(apiUrls.addComment, { blog: id, comment });
      getBlog();
      setComment('');
      successToast('Successfully commented!');
    } catch (error) {
      console.log(error);
    }
  };
  return (
    <div>
      <Page title="Dashboard: Blog">
        <Container>
          <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
            <Button
              variant="contained"
              component={RouterLink}
              to="/dashboard/blog"
              startIcon={<Iconify icon="akar-icons:arrow-back" />}
            >
              Go Back
            </Button>
            <div>
              <Button
                variant="contained"
                onClick={deleteBlog}
                color="error"
                startIcon={<Iconify icon="akar-icons:trash-bin" />}
              >
                Delete
              </Button>
              <Button
                variant="contained"
                style={{ marginLeft: '15px' }}
                onClick={() => setEditModel(true)}
                color="warning"
                startIcon={<Iconify icon="akar-icons:edit" />}
              >
                Edit
              </Button>
            </div>
          </Stack>
          {blog.description && parse(blog.description)}
          <Stack>
            <Card variant="outlined" sx={{ marginTop: '10px' }}>
              {blog?.comments?.length > 0 ? (
                blog.comments.map((cmt) => (
                  <CardContent key={cmt._id}>
                    {cmt?.comment}
                    <p style={{fontSize: "12px"}}>{new Date(cmt.cTime).toLocaleDateString()}</p>
                  </CardContent>
                ))
              ) : (
                <CardContent>No Comments</CardContent>
              )}
            </Card>
          </Stack>
          <Stack marginTop={5} spacing={2}>
            <TextareaAutosize onChange={(e) => setComment(e.target.value)} minRows={5} value={comment} />
            <Button
              onClick={addComment}
              variant="contained"
              size="small"
              startIcon={<Iconify icon="fa-regular:comment-dots" />}
            >
              Comment
            </Button>
          </Stack>
        </Container>
        <Modal
          isOpen={editModel}
          className="reactMyModal"
          overlayClassName="reactModalOverlay"
          contentLabel="Example Modal"
        >
          <Container>
            <Box>
              <Stack direction={'row'} justifyContent={'end'}>
                <Button variant="contained" color="error" onClick={() => setEditModel(false)}>
                  Close
                </Button>
              </Stack>
              <EditBlog modelClose={setEditModel} defaultData={blog} />
            </Box>
          </Container>
        </Modal>
      </Page>
    </div>
  );
}

export default BlogView;
