/* eslint-disable jsx-a11y/tabindex-no-positive */
import { Box, Card, CardHeader, Grid, TextareaAutosize, TextField } from '@mui/material';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import JoditEditor from 'jodit-react';

BlogEditor.propTypes = {
  blogInfo: PropTypes.object,
  setBlogInfo: PropTypes.func,
  children: PropTypes.node,
};

const config = {
  removeButtons: ['source'],
  height: '400px',
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
};

function BlogEditor({ blogInfo, setBlogInfo, children }) {
  const editor = useRef(null);
  return (
    <Card style={{ height: '500px', overflow: 'auto', }}>
      <CardHeader title={'Add Blog'} />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <Grid container spacing={2}>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              label="Title"
              fullWidth
              id="outlined-size-small"
              size="small"
              value={blogInfo?.title || ''}
              onChange={(e) => setBlogInfo((prev) => ({ ...prev, title: e.target.value }))}
              required
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              label="Custom Title"
              fullWidth
              id="outlined-size-small"
              size="small"
              value={blogInfo?.customTitle || ''}
              onChange={(e) => setBlogInfo((prev) => ({ ...prev, customTitle: e.target.value }))}
              required
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              label="Custom url"
              fullWidth
              id="outlined-size-small"
              size="small"
              value={blogInfo?.customUrl || ''}
              onChange={(e) => setBlogInfo((prev) => ({ ...prev, customUrl: e.target.value }))}
              required
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              label="Author"
              id="outlined-size-small"
              size="small"
              value={blogInfo?.author || ''}
              onChange={(e) => setBlogInfo((prev) => ({ ...prev, author: e.target.value }))}
              required
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              id="outlined-size-small"
              fullWidth
              size="small"
              type={'file'}
              onChange={(e) => setBlogInfo((prev) => ({ ...prev, image: e.target.files[0] }))}
              required
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              label="Meta tags separate by space"
              id="outlined-size-small"
              size="small"
              value={blogInfo?.metaTags?.join(' ') || ''}
              onChange={(e) => setBlogInfo((prev) => ({ ...prev, metaTags: e.target.value.split(' ') }))}
            />
          </Grid>
          <Grid item md={8} sm={12} xs={12}>
            <TextareaAutosize
              maxRows={4}
              aria-label="Meta Description"
              placeholder="Meta Description"
              value={blogInfo?.metaDescription || ''}
              onChange={(e) => setBlogInfo((prev) => ({ ...prev, metaDescription: e.target.value }))}
              style={{ width: '100%', minHeight: '100px', resize: 'none' }}
              required
            />
          </Grid>
          {children}
          <Grid item md={12} sm={12} xs={12}>
            <JoditEditor
              ref={editor}
              value={blogInfo?.description || ''}
              config={config}
              tabIndex={1} // tabIndex of textarea
              onBlur={(newContent) => setBlogInfo((prev) => ({ ...prev, description: newContent }))}
            />
          </Grid>
        </Grid>
      </Box>
    </Card>
  );
}

export default BlogEditor;
