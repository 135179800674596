import { Box, Card, CardHeader, Grid, TextareaAutosize, TextField } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

MediaEditor.propTypes = {
  mediaInfo: PropTypes.object,
  setMediaInfo: PropTypes.func,
  children: PropTypes.node,
};

function MediaEditor({ mediaInfo, setMediaInfo, children }) {
  return (
    <Card>
      <CardHeader title={'Add Media'} />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <Grid container spacing={2}>
          {!mediaInfo?._id && (
            <Grid item md={4} sm={6} xs={12}>
              <TextField
                id="outlined-size-small"
                fullWidth
               
                size="small"
                type={'file'}
                onChange={(e) => setMediaInfo((prev) => ({ ...prev, image: e.target.files[0] }))}
                required
              />
            </Grid>
          )}
          <Grid item md={8} sm={12} xs={12}>
            <TextareaAutosize
              maxRows={4}
              aria-label="Description"
              placeholder="Description"
              value={mediaInfo?.description || ''}
              onChange={(e) => setMediaInfo((prev) => ({ ...prev, description: e.target.value }))}
              style={{ width: '100%', minHeight: '100px', resize: 'none' }}
              required
            />
          </Grid>
          {children}
        </Grid>
      </Box>
    </Card>
  );
}

export default MediaEditor;
