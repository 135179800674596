import { toast } from 'react-toastify';
import swal from 'sweetalert';

export const errorToast = (message) =>
  toast.error(message || 'Something went wrong, try again', {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

export const successToast = (message) =>
  toast.success(message || 'success', {
    position: 'top-right',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
  });

export const alertPermission = async (message) => {
  const result = await swal(message, {
    buttons: ['Cancel!', 'OK'],
    icon: 'warning',
    dangerMode: true,
  });
  return result;
};
