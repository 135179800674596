import { Button, Grid } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { apiUrls } from '../../../utils/apiUrls';
import PortfolioEditor from './portfolioEditor';
import { successToast, errorToast } from '../../../utils/toastNotification';
import { uploadImage } from '../../../utils/imageUpload';

SavePortfolio.propTypes = {
  fetchAll: PropTypes.func,
  modelClose: PropTypes.func,
};

function SavePortfolio({ fetchAll, modelClose }) {
  const [portfolioInfo, setPortfolioInfo] = useState({});

  const savePortfolio = async () => {
    try {
      const data = {
        ...portfolioInfo,
      };

      if (portfolioInfo.image) {
        const _uploadImage = await uploadImage(portfolioInfo.image);
        data.file = _uploadImage;
      }

      await axios.post(apiUrls.savePortfolio, data);
      successToast('successfully added portfolio!');
      setPortfolioInfo({});
      fetchAll();
      modelClose(false);
    } catch (error) {
      console.log(error.response);

      errorToast(error?.response?.data?.message || 'Something went wrong, try again');
    }
  };

  return (
    <PortfolioEditor portfolioInfo={portfolioInfo} setPortfolioInfo={setPortfolioInfo}>
      <Grid item md={4} sm={6} xs={12} sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <Button variant="contained" onClick={savePortfolio}>
          Save portfolio
        </Button>
      </Grid>
    </PortfolioEditor>
  );
}

export default SavePortfolio;
