import { Box, Card, CardHeader, Grid } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

ContactView.propTypes = {
  defaultData: PropTypes.object,
  children: PropTypes.node,
};

function ContactView({ defaultData, children }) {
  return (
    <Card style={{ height: 'auto', padding: "1rem" }}>
      <CardHeader title={'Add Section'} />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <Grid container spacing={2}>
          <Grid item md={4} sm={6} xs={12}>
            Name: {defaultData?.name}
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            Email: {defaultData?.email}
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            Phone: {defaultData?.phone}
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            Submitted At: {defaultData?.createdAt}
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <p>Message:</p>
            <p>{defaultData?.message}</p>
          </Grid>
          {children}
        </Grid>
      </Box>
    </Card>
  );
}

export default ContactView;
