import { Button, Grid } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { errorToast, successToast } from '../../../utils/toastNotification';
import { apiUrls } from '../../../utils/apiUrls';
import MediaEditor from './MediaEditor';
import { uploadImage } from '../../../utils/imageUpload';

EditMedia.propTypes = {
  fetchAll: PropTypes.func,
  modelClose: PropTypes.func,
  defaultData: PropTypes.object,
};

function EditMedia({ fetchAll, modelClose, defaultData }) {
  const [mediaInfo, setMediaInfo] = useState(defaultData);

  const editMedia = async () => {
    try {
      const data = {
        ...mediaInfo,
      };

      if (mediaInfo.image) {
        const _uploadImage = await uploadImage(mediaInfo.image);
        data.file = _uploadImage;
      }
      
      await axios.patch(apiUrls.updateMedia(mediaInfo._id), data);

      successToast('successfully update media');
      setMediaInfo({});
      fetchAll();
      modelClose(false);
    } catch (error) {
      console.log(error);
      errorToast();
    }
  };

  return (
    <MediaEditor mediaInfo={mediaInfo} setMediaInfo={setMediaInfo}>
      <Grid item md={4} sm={6} xs={12} sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <Button variant="contained" onClick={editMedia}>
          Update Media
        </Button>
      </Grid>
    </MediaEditor>
  );
}

export default EditMedia;
