import { Box, Card, CardHeader, Grid, TextareaAutosize, TextField } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

Section.propTypes = {
  sectionInfo: PropTypes.object,
  setSectionInfo: PropTypes.func,
  children: PropTypes.node,
};

function Section({ sectionInfo, setSectionInfo, children }) {
  console.log(sectionInfo);
  return (
    <Card>
      <CardHeader title={'Add Section'} />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <Grid container spacing={2}>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              label="Name"
              required
              fullWidth
              id="outlined-size-small"
              size="small"
              value={sectionInfo?.name || ''}
              onChange={(e) => setSectionInfo((prev) => ({ ...prev, name: e.target.value }))}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              label="Custom Title"
              required
              fullWidth
              id="outlined-size-small"
              size="small"
              value={sectionInfo?.customTitle || ''}
              onChange={(e) => setSectionInfo((prev) => ({ ...prev, customTitle: e.target.value }))}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              required
              label="Link"
              id="outlined-size-small"
              size="small"
              value={sectionInfo?.link || ''}
              onChange={(e) => setSectionInfo((prev) => ({ ...prev, link: e.target.value }))}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              label="Custom Url"
              required
              id="outlined-size-small"
              size="small"
              value={sectionInfo?.customUrl || ''}
              onChange={(e) => setSectionInfo((prev) => ({ ...prev, customUrl: e.target.value }))}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              required
              label="Meta tags separate by space"
              id="outlined-size-small"
              size="small"
              value={sectionInfo?.metaTags?.join(" ") || ''}
              onChange={(e) => setSectionInfo((prev) => ({ ...prev, metaTags: e.target.value.split(" ") }))}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              id="outlined-size-small"
              fullWidth
              required
              size="small"
              type={'file'}
              onChange={(e) => setSectionInfo((prev) => ({ ...prev, image: e.target.files[0] }))}
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <TextareaAutosize
              maxRows={4}
              required
              aria-label="Description"
              placeholder="Description"
              value={sectionInfo?.description || ''}
              onChange={(e) => setSectionInfo((prev) => ({ ...prev, description: e.target.value }))}
              style={{ width: '100%', minHeight: '100px', resize: "none" }}
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <TextareaAutosize
              maxRows={4}
              required
              aria-label="Meta description"
              placeholder="Meta description"
              value={sectionInfo?.metaDescription || ''}
              onChange={(e) => setSectionInfo((prev) => ({ ...prev, metaDescription: e.target.value }))}
              style={{ width: '100%', minHeight: '100px', resize: "none" }}
            />
          </Grid>
          {children}
        </Grid>
      </Box>
    </Card>
  );
}

export default Section;
