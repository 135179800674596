import { Button, Grid } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { errorToast, successToast } from '../../../utils/toastNotification';
import { apiUrls } from '../../../utils/apiUrls';
import CareerEditor from './CareerEditor';
import { uploadImage } from '../../../utils/imageUpload';

EditCareer.propTypes = {
  fetchAll: PropTypes.func,
  modelClose: PropTypes.func,
  defaultData: PropTypes.object,
};

function EditCareer({ fetchAll, modelClose, defaultData }) {
  const [careerInfo, setCareerInfo] = useState(defaultData);

  const editCareer = async () => {
    try {
      const data = {
        ...careerInfo,
      };

      if (careerInfo.image) {
        const _uploadImage = await uploadImage(careerInfo.image);
        data.file = _uploadImage;
      }
      
      await axios.patch(apiUrls.updateCareer(careerInfo._id), data);

      successToast('successfully update career');
      setCareerInfo({});
      fetchAll();
      modelClose(false);
    } catch (error) {
      console.log(error);

      errorToast();
    }
  };

  return (
    <CareerEditor careerInfo={careerInfo} setCareerInfo={setCareerInfo}>
      <Grid item md={4} sm={6} xs={12} sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <Button variant="contained" onClick={editCareer}>
          Update Career
        </Button>
      </Grid>
    </CareerEditor>
  );
}

export default EditCareer;
