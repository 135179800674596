import { Box, Card, CardHeader, Grid, TextareaAutosize, TextField } from '@mui/material';
import React from 'react';
import PropTypes from 'prop-types';

Review.propTypes = {
    reviewInfo: PropTypes.object,
    setReviewInfo: PropTypes.func,
    children: PropTypes.node,
};

function Review({ reviewInfo, setReviewInfo, children }) {
     console.log(reviewInfo);
    return (
        <Card>
            <CardHeader title={'Add Review'} />
            <Box sx={{ p: 3, pb: 1 }} dir="ltr">
                <Grid container spacing={2}>
                    <Grid item md={4} sm={6} xs={12}>
                        <TextField
                            label="Name"
                            required
                            fullWidth
                            id="outlined-size-small"
                            size="small"
                            value={reviewInfo?.name || ''}
                            onChange={(e) => setReviewInfo((prev) => ({ ...prev, name: e.target.value }))}
                        />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                        <TextField
                            label="Designation"
                            required
                            fullWidth
                            id="outlined-size-small"
                            size="small"
                            value={reviewInfo?.designation || ''}
                            onChange={(e) => setReviewInfo((prev) => ({ ...prev, designation: e.target.value }))}
                        />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                        <TextField
                            fullWidth
                            required
                            label="Video Url"
                            id="outlined-size-small"
                            size="small"
                            value={reviewInfo?.videoUrl || ''}
                            onChange={(e) => setReviewInfo((prev) => ({ ...prev, videoUrl: e.target.value }))}
                        />
                    </Grid>
                    <Grid item md={4} sm={6} xs={12}>
                        <TextField
                            id="outlined-size-small"
                            fullWidth
                            required
                            size="small"
                            type={'file'}
                            onChange={(e) => setReviewInfo((prev) => ({ ...prev, image: e.target.files[0] }))}
                        />
                    </Grid>
                    <Grid item md={6} sm={12} xs={12}>
                        <TextareaAutosize
                            maxRows={4}
                            required
                            aria-label="Message"
                            placeholder="Message"
                            value={reviewInfo?.message || ''}
                            onChange={(e) => setReviewInfo((prev) => ({ ...prev, message: e.target.value }))}
                            style={{ width: '100%', minHeight: '100px', resize: "none" }}
                        />
                    </Grid>

                    {children}
                </Grid>
            </Box>
        </Card>
    );
}

export default Review;
