import React, { useEffect, useState } from 'react';
import { Stack, Typography, Button, Container, Box } from '@mui/material';
import axios from 'axios';
// eslint-disable-next-line import/named
import { ContactTable, ContactView } from '../sections/@dashboard/contact';
import { apiUrls } from '../utils/apiUrls';
import { alertPermission, successToast, errorToast } from '../utils/toastNotification';
import DashModel from '../components/Modal';

function Portfolio() {
  const [allContact, setAllContact] = useState([]);
  const [editModel, setEditModel] = useState(false);
  const [contactData, setContactData] = useState({});
  console.log(contactData);

  const fetchContact = async () => {
    try {
      const _fetchContact = await axios.get(apiUrls.getContact);
      setAllContact(_fetchContact.data);
      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  useEffect(() => {
    fetchContact();
  }, []);

  const viewContact = (e) => {
    setContactData(e);
    setEditModel(true);
  };
  const deleteContact = async (portfolio) => {
    try {
      const result = await alertPermission('Are you sure you want to delete this?');

      if (result) {
        await axios.delete(apiUrls.deleteContact(portfolio._id));
        successToast('Successfully deleted');
        fetchContact();
      }
    } catch (error) {
      console.log(error.response);
      errorToast(error?.response?.data.message);
    }
  };

  return (
    <Container>
      <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
        <Typography variant="h4" gutterBottom>
          Contact Message
        </Typography>

      </Stack>

      <ContactTable data={allContact} viewContact={viewContact} deleteAction={deleteContact} />

      <DashModel open={editModel} setOpen={setEditModel}>
        <Container>
          <Box>
            <Stack direction={'row'} justifyContent={'end'}>
              <Button variant="contained" color="error" onClick={() => setEditModel(false)}>
                Close
              </Button>
            </Stack>

            <ContactView modelClose={setEditModel} defaultData={contactData} />
          </Box>
        </Container>
      </DashModel>
    </Container>
  );
}

export default Portfolio;
