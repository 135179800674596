import React from 'react';
import Modal from '@mui/material/Modal';
import PropTypes from 'prop-types';

DashModel.propTypes = {
  open: PropTypes.bool,
  children: PropTypes.node,
};
function DashModel({ open, children }) {
  return (
    <Modal open={open} aria-labelledby="keep-mounted-modal-title" aria-describedby="keep-mounted-modal-description">
      <div>{children}</div>
    </Modal>
  );
}

export default DashModel;
