import { Link as RouterLink } from 'react-router-dom';
// material
import { Grid, Button, Container, Stack, Typography } from '@mui/material';
// components
import { useEffect, useState } from 'react';
import axios from 'axios';
import Modal from 'react-modal';
import { apiUrls } from '../utils/apiUrls';
import Page from '../components/Page';
import Iconify from '../components/Iconify';
import { BlogPostCard, SaveBlog } from '../sections/@dashboard/blog';

export default function Blog() {
  const [allBlogs, setAllBlogs] = useState([]);
  const [addNewModel, setAddNewModel] = useState(false);

  const fetchBlogs = async () => {
    try {
      const _fetchBlogs = await axios.get(apiUrls.getBlog);
      setAllBlogs(_fetchBlogs.data);

      return true;
    } catch (error) {
      console.log(error);
      return false;
    }
  };

  useEffect(() => {
    fetchBlogs();
  }, []);

  return (
    <Page title="Dashboard: Blog">
      <Container >
        <Stack direction="row" alignItems="center" justifyContent="space-between" mb={5}>
          <Typography variant="h4" gutterBottom>
            Blog
          </Typography>
          <Button
            variant="contained"
            onClick={() => setAddNewModel(true)}
            component={RouterLink}
            to="#"
            startIcon={<Iconify icon="eva:plus-fill" />}
          >
            New Post
          </Button>
        </Stack>
        <Grid container spacing={3}>
          {allBlogs.map((post, index) => (
            <BlogPostCard key={post._id} post={post} index={index} />
          ))}
        </Grid>
        <Modal
          className="reactMyModal"
          overlayClassName="reactModalOverlay"
          isOpen={addNewModel}
          contentLabel="Example Modal"
        >
          <Stack direction={'row'} justifyContent={'end'}>
            <Button variant="contained" color="error" onClick={() => setAddNewModel(false)}>
              Close
            </Button>
          </Stack>
          <SaveBlog fetchAll={fetchBlogs} modelClose={setAddNewModel} />
        </Modal>
      </Container>
    </Page>
  );
}
