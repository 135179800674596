import React, { createContext, useState } from 'react';
// toast
import { ToastContainer } from 'react-toastify';
// routes
import Router from './routes';
// theme
import ThemeProvider from './theme';
// components
import ScrollToTop from './components/ScrollToTop';
import { BaseOptionChartStyle } from './components/chart/BaseOptionChart';
// toast
import 'react-toastify/dist/ReactToastify.css';
import './app.css';
// ----------------------------------------------------------------------
export const UserContext = createContext(null);

export default function App() {
  const [isLoggedIn, setLoggedIn] = useState(localStorage.getItem('login') || false);

  return (
    <ThemeProvider>
      <UserContext.Provider value={{ isLoggedIn, setLoggedIn }}>
        <ScrollToTop />
        <BaseOptionChartStyle />
        <Router />
        <ToastContainer />
      </UserContext.Provider>
    </ThemeProvider>
  );
}
