/* eslint-disable radix */
import {
  Box,
  Card,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
} from '@mui/material';
import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import axios from 'axios';
import { apiUrls } from '../../../utils/apiUrls';

PortfolioEditor.propTypes = {
  portfolioInfo: PropTypes.object,
  setPortfolioInfo: PropTypes.func,
  children: PropTypes.node,
};

function PortfolioEditor({ portfolioInfo, setPortfolioInfo, children }) {
  const [sections, setSections] = useState([]);

  useEffect(() => {
    (async () => {
      try {
        const _sections = await axios.get(apiUrls.getSection);

        setSections(_sections.data);
      } catch (error) {
        console.log(error);
      }
    })();
  }, []);

  return (
    <Card>
      <CardHeader title={'Add Portfolio'} />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <Grid container spacing={2}>
          <Grid item md={4} sm={6} xs={12}>
            <FormControl fullWidth sx={{ minWidth: 120 }} size="small">
              <InputLabel id="Section-select-small">Section</InputLabel>
              <Select
              required
                labelId="Section-select-small"
                id="Section-select-small"
                value={portfolioInfo?.section || ''}
                label="Section"
                onChange={(e) => setPortfolioInfo((prev) => ({ ...prev, section: e.target.value }))}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                {sections.map((item) => (
                  <MenuItem value={item._id} key={item._id}>
                    {item.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              required
              label="title"
              id="outlined-size-small"
              size="small"
              value={portfolioInfo?.title || ''}
              onChange={(e) => setPortfolioInfo((prev) => ({ ...prev, title: e.target.value }))}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              required
              label="Custom title"
              id="outlined-size-small"
              size="small"
              value={portfolioInfo?.customTitle || ''}
              onChange={(e) => setPortfolioInfo((prev) => ({ ...prev, customTitle: e.target.value }))}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              required
              label="link"
              id="outlined-size-small"
              size="small"
              value={portfolioInfo?.link || ''}
              onChange={(e) => setPortfolioInfo((prev) => ({ ...prev, link: e.target.value }))}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              required
              label="Custom url"
              id="outlined-size-small"
              size="small"
              value={portfolioInfo?.customUrl || ''}
              onChange={(e) => setPortfolioInfo((prev) => ({ ...prev, customUrl: e.target.value }))}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              required
              label="Meta tags separate by space"
              id="outlined-size-small"
              size="small"
              value={portfolioInfo?.metaTags?.join(' ') || ''}
              onChange={(e) => setPortfolioInfo((prev) => ({ ...prev, metaTags: e.target.value.split(' ') }))}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              required
              label="cri"
              id="outlined-size-small"
              size="small"
              type={'number'}
              value={portfolioInfo?.cri || ''}
              onChange={(e) => setPortfolioInfo((prev) => ({ ...prev, cri: e.target.value }))}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              required
              label="cpl"
              id="outlined-size-small"
              size="small"
              type={'number'}
              value={portfolioInfo?.cpl || ''}
              onChange={(e) => setPortfolioInfo((prev) => ({ ...prev, cpl: e.target.value }))}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              id="outlined-size-small"
              fullWidth
              required
              size="small"
              type={'file'}
              onChange={(e) => setPortfolioInfo((prev) => ({ ...prev, image: e.target.files[0] }))}
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <TextareaAutosize
              maxRows={4}
              required
              aria-label="Description"
              placeholder="Description"
              value={portfolioInfo?.description || ''}
              onChange={(e) => setPortfolioInfo((prev) => ({ ...prev, description: e.target.value }))}
              style={{ width: '100%', minHeight: '100px', resize: 'none' }}
            />
          </Grid>
          <Grid item md={6} sm={12} xs={12}>
            <TextareaAutosize
              maxRows={4}
              required
              aria-label="Meta description"
              placeholder="Meta description"
              value={portfolioInfo?.metaDescription || ''}
              onChange={(e) => setPortfolioInfo((prev) => ({ ...prev, metaDescription: e.target.value }))}
              style={{ width: '100%', minHeight: '100px', resize: 'none' }}
            />
          </Grid>
          {children}
        </Grid>
      </Box>
    </Card>
  );
}

export default PortfolioEditor;
