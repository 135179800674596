import {
  Box,
  Card,
  CardHeader,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select,
  TextareaAutosize,
  TextField,
} from '@mui/material';
import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import JoditEditor from 'jodit-react';

CareerEditor.propTypes = {
  careerInfo: PropTypes.object,
  setCareerInfo: PropTypes.func,
  children: PropTypes.node,
};

const config = {
  removeButtons: ['source'],
  height: '300px',
  readonly: false, // all options from https://xdsoft.net/jodit/doc/
};

function CareerEditor({ careerInfo, setCareerInfo, children }) {
  const editor = useRef(null);
  return (
    <Card sx={{ height: '500px', overflow: 'auto', }}>
      <CardHeader title={'Add Career'} />
      <Box sx={{ p: 3, pb: 1 }} dir="ltr">
        <Grid container spacing={2}>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              label="Title"
              fullWidth
              id="outlined-size-small"
              size="small"
              value={careerInfo?.title || ''}
              onChange={(e) => setCareerInfo((prev) => ({ ...prev, title: e.target.value }))}
              required
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              label="Custom Title"
              fullWidth
              id="outlined-size-small"
              size="small"
              value={careerInfo?.customTitle || ''}
              onChange={(e) => setCareerInfo((prev) => ({ ...prev, customTitle: e.target.value }))}
              required
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              label="Meta tags separate by space"
              id="outlined-size-small"
              size="small"
              value={careerInfo?.metaTags?.join(' ') || ''}
              onChange={(e) => setCareerInfo((prev) => ({ ...prev, metaTags: e.target.value.split(' ') }))}
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <FormControl fullWidth sx={{ minWidth: 120 }} size="small">
              <InputLabel id="Section-select-small">Type*</InputLabel>
              <Select
                labelId="Section-select-small"
                id="Section-select-small"
                value={careerInfo?.type || ''}
                label="Type"
                onChange={(e) => setCareerInfo((prev) => ({ ...prev, type: e.target.value }))}
              >
                <MenuItem value="">
                  <em>None</em>
                </MenuItem>
                <MenuItem value="On-Site">
                  <em>On-Site</em>
                </MenuItem>
                <MenuItem value="Remote">
                  <em>Remote</em>
                </MenuItem>
                <MenuItem value="Hybrid">
                  <em>Hybrid</em>
                </MenuItem>
              </Select>
            </FormControl>
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              label="Custom Url"
              id="outlined-size-small"
              size="small"
              value={careerInfo?.customUrl || ''}
              onChange={(e) => setCareerInfo((prev) => ({ ...prev, customUrl: e.target.value }))}
              required
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              fullWidth
              label="Salary"
              id="outlined-size-small"
              type={'number'}
              size="small"
              value={careerInfo?.salary || ''}
              onChange={(e) => setCareerInfo((prev) => ({ ...prev, salary: e.target.value }))}
              required
            />
          </Grid>
          <Grid item md={4} sm={6} xs={12}>
            <TextField
              id="outlined-size-small"
              fullWidth
              size="small"
              type={'file'}
              onChange={(e) => setCareerInfo((prev) => ({ ...prev, image: e.target.files[0] }))}
              required
            />
          </Grid>
          <Grid item md={8} sm={12} xs={12}>
            <TextareaAutosize
              maxRows={4}
              aria-label="Meta Description"
              placeholder="Meta Description"
              value={careerInfo?.metaDescription || ''}
              onChange={(e) => setCareerInfo((prev) => ({ ...prev, metaDescription: e.target.value }))}
              style={{ width: '100%', minHeight: '100px', resize: 'none' }}
              required
            />
          </Grid>
          <Grid item md={12} sm={12} xs={12}>
            <JoditEditor
              ref={editor}
              value={careerInfo?.description || ''}
              config={config}
              onBlur={(newContent) => setCareerInfo((prev) => ({ ...prev, description: newContent }))}
            />
          </Grid>
          {children}
        </Grid>
      </Box>
    </Card>
  );
}

export default CareerEditor;
