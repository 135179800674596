import { Button, Grid } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { errorToast, successToast } from '../../../utils/toastNotification';
import { apiUrls } from '../../../utils/apiUrls';
import PortfolioEditor from './portfolioEditor';
import { uploadImage } from '../../../utils/imageUpload';

EditPortfolio.propTypes = {
  fetchAll: PropTypes.func,
  modelClose: PropTypes.func,
  defaultData: PropTypes.object,
};

function EditPortfolio({ fetchAll, modelClose, defaultData }) {
  const [portfolioInfo, setPortfolioInfo] = useState(defaultData);

  const editPortfolio = async () => {
    try {
      const data = {
        ...portfolioInfo,
      };

      if (portfolioInfo.image) {
        const _uploadImage = await uploadImage(portfolioInfo.image);
        data.file = _uploadImage;
      }
      
      await axios.patch(apiUrls.updatePortfolio(portfolioInfo._id), data);

      successToast('successfully update portfolio');
      setPortfolioInfo({});
      fetchAll();
      modelClose(false);
    } catch (error) {
      console.log(error);

      errorToast();
    }
  };

  return (
    <PortfolioEditor portfolioInfo={portfolioInfo} setPortfolioInfo={setPortfolioInfo}>
      <Grid item md={4} sm={6} xs={12} sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <Button variant="contained" onClick={editPortfolio}>
          Update Portfolio
        </Button>
      </Grid>
    </PortfolioEditor>
  );
}

export default EditPortfolio;
