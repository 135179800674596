import React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import PropTypes from 'prop-types';
import { Stack, Button } from '@mui/material';
import moment from 'moment'; // Import moment
import Iconify from '../../../components/Iconify';

const columns = (viewContact, deleteAction) => [
  { field: 'sr', headerName: 'SL.', width: 100 },
  { field: 'name', headerName: 'Name', width: 130 },
  { field: 'email', headerName: 'Email', width: 130 },
  { field: 'phone', headerName: 'Phone', width: 130 },
  {
    field: 'createdAt',
    headerName: 'Submitted At',
    width: 130,
    valueFormatter: (params) => moment(params.value).format('lll'), // Format createdAt field
  },
  { field: 'message', headerName: 'Message', width: 300 },
  {
    field: 'action',
    headerName: 'Action',
    sortable: false,
    width: 200,
    renderCell: ({ row }) => (
      <Stack direction={'row'} spacing={1}>
        <Button
          onClick={() => viewContact(row)}
          variant="contained"
          startIcon={<Iconify icon="ant-design:eye-filled" />}
        >
          View
        </Button>

        <Button
          onClick={() => deleteAction(row)}
          variant="contained"
          color="error"
          startIcon={<Iconify icon="ant-design:eye-filled" />}
        >
          Delete
        </Button>
      </Stack>
    ),
  }
];

ContactTable.propTypes = {
  data: PropTypes.array,
  viewContact: PropTypes.object,
  deleteAction: PropTypes.object
};

function ContactTable({ data, viewContact, deleteAction }) {
  return (
    <div style={{ height: 650, width: '100%' }}>
      <DataGrid
        disableColumnMenu
        rows={data?.reverse()?.map((row, index) => ({
          ...row,
          createdAt: moment(row.createdAt).format('lll'),
          sr: index + 1, // Format createdAt in each row
        }))}
        columns={columns(viewContact, deleteAction)}
        pageSize={10}
        rowsPerPageOptions={[10]}
        getRowId={(row) => row._id}
      />
    </div>
  );
}

export default ContactTable;
