import { Button, Grid } from '@mui/material';
import axios from 'axios';
import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { errorToast, successToast } from '../../../utils/toastNotification';
import { apiUrls } from '../../../utils/apiUrls';
import Section from './SectionEditor';
import { uploadImage } from '../../../utils/imageUpload';

EditSection.propTypes = {
  fetchAll: PropTypes.func,
  modelClose: PropTypes.func,
  defaultData: PropTypes.object,
};

function EditSection({ fetchAll, modelClose, defaultData }) {
  const [sectionInfo, setSectionInfo] = useState(defaultData);

  const editSection = async () => {
    try {
      const data = {
        ...sectionInfo,
      };

      if (sectionInfo.image) {
        const _uploadImage = await uploadImage(sectionInfo.image);
        data.file = _uploadImage;
      }

      await axios.patch(apiUrls.updateSection(sectionInfo._id), data);

      successToast('successfully update section');
      setSectionInfo({});
      fetchAll();
      modelClose(false);
    } catch (error) {
      console.log(error);

      errorToast();
    }
  };

  return (
    <Section sectionInfo={sectionInfo} setSectionInfo={setSectionInfo}>
      <Grid item md={4} sm={6} xs={12} sx={{ display: 'flex', alignItems: 'flex-end' }}>
        <Button variant="contained" onClick={editSection}>
          Update section
        </Button>
      </Grid>
    </Section>
  );
}

export default EditSection;
