import axios from 'axios';

export const uploadImage = async (image) => {
  try {
    if (!image) {
      throw new Error('Image not available')
    }
    
    const formData = new FormData();

    formData.set('key', 'a18e1fb7a5966531fe1226c0b6ff0ba0');
    formData.append('image', image);

    const uploadImage = await axios.post(`https://api.imgbb.com/1/upload`, formData);

    return uploadImage.data.data.display_url;
  } catch (error) {
    console.log(error);
    return false;
  }
};
